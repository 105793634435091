import { React, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, HelpDialog } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField, TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from "dayjs";
import { ButtonGroup } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";
import { subMonths } from 'date-fns'
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});

const { format } = require('date-fns');
let prevfrmDate;
let prevtoDate;

const PurReturnChkList = (props) => {

    const classes = useStyles();
    const [PRetChkData, handleStats] = useState([]);
    const [frmDate, setfrmDate] = useState(subMonths(new Date(), 1));//useState(subMonths(new Date(), 60));
    const [toDate, settoDate] = useState(new Date());
    let CmphCode = '';

    useEffect(() => {
        FetchData()
    }, [])

    function getDates() {
        const $frmDate = format(new Date(frmDate), 'yyyy-MM-dd');
        const $toDate = format(new Date(toDate), 'yyyy-MM-dd');
        setfrmDate($frmDate);
        settoDate($toDate);
       // FetchData();
    }

    const FetchData = async () => {
        const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/PurReturnChkList?CmphCode=' + CmphCode + '&FrmDate=' + format(new Date(frmDate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(toDate), 'yyyy-MM-dd')); //data source;  
        const PRetChkData = await data.json()
        handleStats(PRetChkData)
        setFilter(PRetChkData);
    }

    if (prevfrmDate !== frmDate || prevtoDate !== toDate) {
       // FetchData();
        prevfrmDate = frmDate;
        prevtoDate = toDate;
    }
    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = PRetChkData.filter((row) => {
            return row.PRethRefNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CmphName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PRetdQnty.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PRetdRate.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PRetdDiscRate.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PRetdDflDisc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PRetdDflAmnt.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length < 1) {
            setFilter(PRetChkData)
        }
        else {
            setFilter(filteredRows)
        }
    };
    const PRethRefNo_GROUP = [...PRetChkData.reduce((r, o) => {
        const key = o.PRethRefNo;
        const item = r.get(key) || Object.assign({}, o);
        return r.set(key, item);
    }, new Map).values()];

    // //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    ///--------------------HELP WORK START----------------------------
    const [helpCmphCode, setCmphCode] = useState("");

    const handleDataChange = (newData) => {
        setCmphCode(newData);
    };
    const handleChange = (e) => {
        setCmphCode(e.target.value);
    };

    // const handleKeyDown = (event) => {
    //   if (event.key === "F1") {
    //     event.preventDefault();

    //     document.getElementById("HelpDialog").click();
    //   }
    // }

    function getCmphCode() {

        CmphCode = document.getElementById("helpCompanyTextbox").value;
    }

    function setFilterClick() {
        getCmphCode();
        getDates();
        FetchData();
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(true);
        //----TOTAL----------------------
        let total_Qnty = 0;
        let total_Amnt = 0;
        let total_Value = 0;

        return (
            <Fragment>
                {/* {console.log(filter.map((row) => { return row.BookerCode.toString() }))} */}

                {filter.map((row) => { return row.PRethRefNo }).includes(row.PRethRefNo) && <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                    <StyledTableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                    </StyledTableCell>
                    <TableCell component="th" scope="row"><span className='fontStyle'> Ref. No. : </span>{row.PRethRefNo}</TableCell>
                    <TableCell align="left"><span className='fontStyle'>Ref. Date : </span>{format(new Date(row.PRethRefDate), 'dd/MM/yyyy')}</TableCell>
                    <TableCell align="left"><span className='fontStyle'>Supp. Name : </span>{row.CmphName}</TableCell>
                </TableRow>}

                {filter.map((row) => { return row.PRethRefNo }).includes(row.PRethRefNo) && <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {/* {row.BookerCode} */}
                                </Typography>

                                <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                                    {/* <Table size="small" aria-label="purchases"> */}
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableHeadCell align="left">Item Description</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Quantity</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Rate</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Disc. Percentage</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Disc. Amount</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Value</StyledTableHeadCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filter.filter(Detrow => ((Detrow.PRethRefNo === row.PRethRefNo))).map((DetailRow) => (
                                            total_Qnty += DetailRow.PRetdQnty,
                                            total_Amnt += DetailRow.PRetdDflDisc,
                                            total_Value += DetailRow.PRetdDflAmnt,

                                            <StyledTableRow key={DetailRow.Id}>
                                                <StyledTableCell align="left">{DetailRow.ItemDesc}</StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PRetdQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PRetdRate} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PRetdDiscRate} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PRetdDflDisc} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PRetdDflAmnt} decimal={2} /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        <StyledTableRow>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Sub Total</StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Qnty} decimal={2} /></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Amnt} decimal={2} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Value} decimal={2} /></StyledTableFooterCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>}
            </Fragment>
        );
    }
    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title="Purchase Return Check List" />
            <div style={{ marginTop: "-1.5rem" }}>

                <TextField id="helpCompanyTextbox" value={helpCmphCode} onChange={handleChange} label="Company Code" variant="standard" //placeholder='Help Sale Order' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpCompany'} setHelpData={handleDataChange} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="From Date"
                        value={frmDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setfrmDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <DesktopDatePicker
                        label="To Date"
                        value={toDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                settoDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                </LocalizationProvider>
                <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={setFilterClick}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            {/* ----------------------*/}
            {/* <TablePagination
                component="div"
                rowsPerPageOptions={[1,5, 10, 15, 20, 25, 50, 100]}
                count={PRethRefNo_GROUP.length}
                showFirstButton
                showLastButton
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                //   onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <TableContainer component={Paper}>
                <Table id="myTable" aria-label="sticky table" size={'small'}>
                    {/* <TableHead>
                        <TableRow>
                            <TableCell />
                            <StyledTableHeadCell align="left">Ref No.</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Ref Date</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Supp. Name</StyledTableHeadCell>
                        </TableRow>
                    </TableHead> */}

                    <TableBody>
                        {(PRethRefNo_GROUP).map((row) => (
                            <Row key={row.Id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
export default PurReturnChkList