import { React, useState, useEffect, Fragment } from 'react';
import { useLocation, Link, NavLink, useNavigate, Routes } from "react-router-dom";
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat,LinearProgressWithLabel, HelpDialog } from '../components';
import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField, TableContainer, TableFooter
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { subMonths } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as Breadcrumbslink } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});

const { format } = require('date-fns');
let prevAsOnDate;
let prevapiName;

const InvWiseConsignment_MonthWise = (props) => {

    const classes = useStyles();

    const [IWCMWDATA, setIWCMWDATA] = useState([]);
    const [AsOnDate, setAsOnDate] = useState(new Date());
    const [error, setError] = useState(null); // Track errors
    const [loading, setLoading] = useState(false); // Track loading state
    const [progress, setProgress] = useState(0);

    // Dynamically setting apiName and rptName based on RptOptn
    const apiName = 'InvWiseCons_New_MonthWise';

    const rptName = 'Invoice Consignment (Month Wise)'

    // Fetch data when component mounts and when `apiName` changes
    useEffect(() => {
        let isMounted = true; // Track mounting status
        const storedData = localStorage.getItem(apiName);
        if (storedData) {
            // If we have stored data, use it
            const parsedData = JSON.parse(storedData);
            if (isMounted) {
                setIWCMWDATA(parsedData);
                setFilter(parsedData);
                setLoading(false); // Stop loading as data is already available
            }
        } else {
            // If no stored data, fetch from API
            FetchData(isMounted);
        }
        // Cleanup function to avoid memory leaks
        return () => {
            isMounted = false;
        };
    }, []); // This ensures that when `apiName` changes, data is re-fetched

    // FetchData function to get data from API
    const FetchData = async (isMounted) => {
        setLoading(true); // Start loading
        setProgress(0);

        const updateProgress = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(updateProgress);
                    return 100;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
        }, 5000);

        try {
            const formattedDate = format(new Date(AsOnDate), 'yyyy-MM-dd');
            const response = await fetch(`${process.env.REACT_APP_SERVER_NAME}/api/${apiName}?AsOnDate=${formattedDate}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (isMounted) {
                setProgress(100);
                setIWCMWDATA(data);
                setFilter(data);
            }
            // Store the fetched data in localStorage with apiName as the key
            localStorage.setItem(apiName, JSON.stringify(data));
        } catch (error) {
            console.error('Failed to fetch data:', error);
            if (isMounted) {
                setError('Failed to fetch data. Please try again later.');
                setProgress(0);
            }
        } finally {
            if (isMounted) {
                clearInterval(updateProgress);
                setLoading(false); // Stop loading after fetch attempt
            }
        }
    };


    // Function to handle date change
    const getDates = () => {
        const $AsOnDate = format(new Date(AsOnDate), 'yyyy-MM-dd');
        setAsOnDate($AsOnDate);
        FetchData(); // Fetch new data when date changes
    };

    if (prevapiName !== apiName) {
        FetchData();
        prevapiName = apiName;
    }

    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = IWCMWDATA.filter((row) => {
            return row.CsName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.AreaName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });

        if (searchedVal.length < 1) {
            setFilter(IWCMWDATA)
        }
        else {
            setFilter(filteredRows)
        }
    };
    //----------------grouping------------------------------
    const company_GROUP = [...IWCMWDATA.reduce((r, o) => {
        const key = o.MonthYear;
        const date = r.get(key) || Object.assign({}, o);
        return r.set(key, date);
    }, new Map).values()];
    // console.log(company_GROUP);

    // company_GROUP.sort((a, b) => {
    //     // Split MonthYear into year and month
    //     const [yearA, monthA] = a.MonthYear.split('-');
    //     const [yearB, monthB] = b.MonthYear.split('-');

    //     // Compare years first
    //     if (yearA !== yearB) {
    //         return yearA - yearB; // Sort by ascending year
    //     } else {
    //         // If years are the same, compare months
    //         return monthA - monthB; // Sort by ascending month
    //     }
    // });

    //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    function setFilterClick() {
        getDates();
        FetchData();
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(true);

        //----TOTAL----------------------
        let total_BillAmount = 0;

        return (
            <Fragment>
                {/* {console.log(filter.map((row) => { return row.ItemCode.toString() }))} */}
                {filter.map((row) => { return row.MonthYear.toString() }).includes(row.MonthYear) && <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <StyledTableCell component="th" scope="row" style={{ fontWeight: "Bold" }}>
                        {row.MonthYear}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" style={{ fontWeight: "Bold" }} >{row.ItemDesc}</StyledTableCell> */}
                </TableRow>}
                {filter.map((row) => { return row.MonthYear.toString() }).includes(row.MonthYear) && <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                {/* <Typography variant="h6" gutterBottom component="div">
                                    {row.ItemDesc}
                                </Typography> */}
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            {/* <StyledTableHeadCell align="left">Item Code</StyledTableHeadCell> */}
                                            <StyledTableHeadCell align="left">DATE</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">PARTY NAME</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">AREA</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">BILL AMOUNT</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">SALE MAN</StyledTableHeadCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filter.filter(Detrow => (Detrow.MonthYear == row.MonthYear)).map((DetailRow) => (
                                            total_BillAmount += ((DetailRow.Opng_Amnt + DetailRow.Sale_Amnt - DetailRow.Retu_Amnt) - DetailRow.Coll_Amnt),

                                            <TableRow key={DetailRow.Id}>
                                                {/* <StyledTableCell component="th" scope="row">
                                                    {DetailRow.ItemCode}
                                                </StyledTableCell> */}
                                                {/* <StyledTableCell align="left" >
                                                    <div className='smallFont'>{DetailRow.ItemDesc}</div>
                                                    {DetailRow.ItemUnit}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/SaleInvoiceDoc/" state={DetailRow}>{format(new Date(DetailRow.SalInvDate), 'dd/MM/yyyy')}</Button></StyledTableCell>
                                                <StyledTableCell align="left">{DetailRow.CsName}</StyledTableCell>
                                                <StyledTableCell align="left">{DetailRow.AreaName}</StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={((DetailRow.Opng_Amnt + DetailRow.Sale_Amnt - DetailRow.Retu_Amnt) - DetailRow.Coll_Amnt)} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="left">{DetailRow.BookerName}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <StyledTableRow>
                                            {/* <StyledTableFooterCell></StyledTableFooterCell> */}
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_BillAmount} /></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>}
            </Fragment>
        );
    }

    // Row.propTypes = {
    //     row: PropTypes.shape({
    //         calories: PropTypes.number.isRequired,
    //         carbs: PropTypes.number.isRequired,
    //         fat: PropTypes.number.isRequired,
    //         history: PropTypes.arrayOf(
    //             PropTypes.shape({
    //                 amount: PropTypes.number.isRequired,
    //                 customerId: PropTypes.string.isRequired,
    //                 date: PropTypes.string.isRequired,
    //             }),
    //         ).isRequired,
    //         name: PropTypes.string.isRequired,
    //         price: PropTypes.number.isRequired,
    //         protein: PropTypes.number.isRequired,
    //     }).isRequired,
    // };

    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title={rptName} />
            <div style={{ marginTop: "-1.5rem" }}>
                {/* <TextField id="helpFromItemTextbox" value={helpFromItem} onChange={handleChangeFrmItm} label="From Item Code" variant="standard" //placeholder='Help Booker' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpItem'} setHelpData={handleDataChangeFrmItm} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <TextField id="helpToItemTextbox" value={helpToItem} onChange={handleChangeToItm} label="To Item Code" variant="standard" //placeholder='Help Sale Order' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpItem'} setHelpData={handleDataChangeToItm} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <DesktopDatePicker
                        label="From Date"
                        value={frmDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setfrmDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                    <DesktopDatePicker
                        label="As On Date"
                        value={AsOnDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setAsOnDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                </LocalizationProvider>
                <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={setFilterClick}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            {/* ----------------------*/}
            {loading && <LinearProgressWithLabel value={progress} />}
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <StyledTableHeadCell align="left">Month</StyledTableHeadCell>
                            {/* <StyledTableHeadCell align="left"></StyledTableHeadCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(company_GROUP).map((row) => (
                            <Row key={row.Id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default InvWiseConsignment_MonthWise

