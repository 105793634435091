import React from 'react'
import {
  TableCell
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableHeadCell = withStyles({
    root: {
      color: "#9c27b0!important",
      fontSize:"0.775rem!important",
    },
  })(TableCell);

// function StyledTableHeadCell() {
//   return (
//     <div></div>
//   )
// }

export default StyledTableHeadCell