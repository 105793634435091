import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TableSortLabel, Paper
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '.';
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';



function SimpleDialog(props) {
    let helpName = props.helpName;

    const useStyles = makeStyles({
        root: {
            width: "100%"
        },
        container: {
            maxHeight: "90vh",
        },
        paperCont: {
            width: 500,
            height: 600
        },
        table: {
            minWidth: 650,
          },
          td: {
            width: "30%",
          },

    });
    const classes = useStyles();

    const [helpDATA, handleStats] = useState([]);
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");

    const { format } = require('date-fns');

    useEffect(() => {
        FetchData()
    }, [])


    const FetchData = async () => {
        const data =
            helpName === "Helpcust" ? await fetch(process.env.REACT_APP_SERVER_NAME + '/api/Partylist/')
                : helpName === "HelpBooker" ? await fetch(process.env.REACT_APP_SERVER_NAME + '/api/HelpBooker')
                    : helpName === "HelpSOrder" ? await fetch(process.env.REACT_APP_SERVER_NAME + '/api/HelpSOrder')
                        : helpName === "HelpItem" ? await fetch(process.env.REACT_APP_SERVER_NAME + '/api/HelpItem')
                            : helpName === "HelpCompany" ? await fetch(process.env.REACT_APP_SERVER_NAME + '/api/HelpCompany')
                                : [""];
        const helpDATA = await data.json()
        handleStats(helpDATA)
        setFilter(helpDATA);
    }

    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    if (helpName === "Helpcust") {

        const requestSearch = (searchedVal) => {
            setShowClearIcon(searchedVal === "" ? "none" : "flex");
            document.getElementById('dialogSearchText').value = searchedVal === "" ? "" : searchedVal;
            const filteredRows = helpDATA.filter((row) => {
                return row.CsCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                    || row.CsName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (searchedVal.length < 1) {
                setFilter(helpDATA)
            }
            else {
                setFilter(filteredRows)
            }
        };

        return (

            <Dialog onClose={handleClose} open={open}>
                  <Paper className={classes.paperCont}>
                <DialogTitle>Help Customer</DialogTitle>
                <TableContainer className={classes.container} >
                    <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'} >
                        <TableHead >
                            <TableRow>
                                <StyledTableHeadCell align="left">Customer Code</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Customer Name</StyledTableHeadCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filter.map((row) => (
                                <StyledTableRow key={row.Id} hover >
                                    <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={() => handleListItemClick(row.CsCode)}>{row.CsCode}</Button></StyledTableCell>
                                    <StyledTableCell align="left" >{row.CsName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
            </Dialog>
        );
    }
    else if (helpName === "HelpBooker") {

        const requestSearch = (searchedVal) => {
            // setPage(0);
            // if (searchedVal !== "") {
            //     setRowsPerPage(-1);
            // } else {
            //     setRowsPerPage(1);
            // }
            setShowClearIcon(searchedVal === "" ? "none" : "flex");
            document.getElementById('dialogSearchText').value = searchedVal === "" ? "" : searchedVal;
            const filteredRows = helpDATA.filter((row) => {
                return row.BookerCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                    || row.BookerName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (searchedVal.length < 1) {
                setFilter(helpDATA)
            }
            else {
                setFilter(filteredRows)
            }
        };

        return (
            <Dialog onClose={handleClose} open={open}>
                <Paper className={classes.paperCont}>
                <DialogTitle>Help Booker</DialogTitle>
                <TextField id='dialogSearchText' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
                    <TableContainer className={classes.container}>
                        <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'} className={classes.table}>
                            <TableHead >
                                <TableRow>
                                    <StyledTableHeadCell align="left" style={{width:'30%', overflow: "hidden",textOverflow: "ellipsis"}}>Booker Code</StyledTableHeadCell>
                                    <StyledTableHeadCell align="left" style={{width:'60%'}}>Booker Name</StyledTableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filter.map((row) => (
                                    <StyledTableRow key={row.Id} hover >
                                        <StyledTableCell align="left" style={{width:'30%'}}><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={() => handleListItemClick(row.BookerCode)}>{row.BookerCode}</Button></StyledTableCell>
                                        <StyledTableCell align="left" style={{width:'60%'}}>{row.BookerName}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

            </Dialog>
        );
    }
    else if (helpName === "HelpSOrder") {

        const requestSearch = (searchedVal) => {
            setShowClearIcon(searchedVal === "" ? "none" : "flex");
            document.getElementById('dialogSearchText').value = searchedVal === "" ? "" : searchedVal;
            const filteredRows = helpDATA.filter((row) => {
                return row.SOrderhRefNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                    || row.CsCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                    || row.CsName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (searchedVal.length < 1) {
                setFilter(helpDATA)
            }
            else {
                setFilter(filteredRows)
            }
        };

        return (

            <Dialog onClose={handleClose} open={open}>
                <Paper className={classes.paperCont}>
                <DialogTitle>Help Sale Order</DialogTitle>
                <TextField id='dialogSearchText' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
                <TableContainer className={classes.container}>
                    <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead >
                            <TableRow>
                                <StyledTableHeadCell align="left">Ref. No.</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Ref. Date</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Cust. Code</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Cust. Name</StyledTableHeadCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filter.map((row) => (
                                <StyledTableRow key={row.Id} hover >
                                    <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={() => handleListItemClick(row.SOrderhRefNo)}>{row.SOrderhRefNo}</Button></StyledTableCell>
                                    <StyledTableCell align="left" >{format(new Date(row.SOrderhRefDt), 'dd/MM/yyyy')}</StyledTableCell>
                                    <StyledTableCell align="left" >{row.CsCode}</StyledTableCell>
                                    <StyledTableCell align="left" >{row.CsName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
            </Dialog>
        );
    }
    else if (helpName === "HelpItem") {

        const requestSearch = (searchedVal) => {

            setShowClearIcon(searchedVal === "" ? "none" : "flex");
            document.getElementById('dialogSearchText').value = searchedVal === "" ? "" : searchedVal;
            const filteredRows = helpDATA.filter((row) => {
                return row.ItemCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                    || row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (searchedVal.length < 1) {
                setFilter(helpDATA)
            }
            else {
                setFilter(filteredRows)
            }
        };

        return (

            <Dialog onClose={handleClose} open={open}>
                <Paper className={classes.paperCont}>
                <DialogTitle>Help Item Code</DialogTitle>
                <TextField id='dialogSearchText' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
                <TableContainer className={classes.container}>
                    <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead >
                            <TableRow>
                                <StyledTableHeadCell align="left">Item Code</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Item Desc.</StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filter.map((row) => (
                                <StyledTableRow key={row.Id} hover >
                                    <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={() => handleListItemClick(row.ItemCode)}>{row.ItemCode}</Button></StyledTableCell>
                                    <StyledTableCell align="left" >{row.ItemDesc}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
            </Dialog>
        );
    }

    else if (helpName === "HelpCompany") {

        const requestSearch = (searchedVal) => {
            setShowClearIcon(searchedVal === "" ? "none" : "flex");
            document.getElementById('dialogSearchText').value = searchedVal === "" ? "" : searchedVal;
            const filteredRows = helpDATA.filter((row) => {
                return row.CmphCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                    || row.CmphName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (searchedVal.length < 1) {
                setFilter(helpDATA)
            }
            else {
                setFilter(filteredRows)
            }
        };

        return (

            <Dialog onClose={handleClose} open={open}>
                  <Paper className={classes.paperCont}>
                <DialogTitle>Help Company Code</DialogTitle>
                <TextField id='dialogSearchText' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
                <TableContainer className={classes.container}>
                    <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead >
                            <TableRow>
                                <StyledTableHeadCell align="left">Company Code</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Company Name</StyledTableHeadCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filter.map((row) => (
                                <StyledTableRow key={row.Id} hover >
                                    <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={() => handleListItemClick(row.CmphCode)}>{row.CmphCode}</Button></StyledTableCell>
                                    <StyledTableCell align="left" >{row.CmphName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
            </Dialog>
        );
    }

    else {
        return (

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Help Not available</DialogTitle>
            </Dialog>
        );
    }
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

const HelpDialog = ({ helpName, setHelpData }) => {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
        setHelpData(value);
    };

    return (
        <div>
            <Button size="small" onClick={handleClickOpen}>
                <HelpOutlineIcon style={{ color: 'purple' }} />
            </Button>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                helpName={helpName}
            />
        </div>
    );
}

export default HelpDialog;