import React from "react";
import  * as ReactDOM from "react-dom";
import App from './App';
import './index.css';
import './App.css';
import {ContextProvider} from './contexts/ContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
<ContextProvider>
<App />
</ContextProvider>,

 document.getElementById('root'));

 window.addEventListener('beforeunload', function () {
    localStorage.removeItem('PSCRDATA');
    localStorage.removeItem('PSCDATA');
    localStorage.removeItem('PSMDATA');
    localStorage.removeItem('InvWiseCredit_MonthWise');
    localStorage.removeItem('InvWiseCons_New_MonthWise');
    localStorage.removeItem('InvWiseMerge_MonthWise');
    localStorage.removeItem('IWCMWDATA');
  });
 // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

