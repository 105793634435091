import { React, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, HelpDialog } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField, TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from "dayjs";
import { ButtonGroup } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";
import { subMonths } from 'date-fns'
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});

const { format } = require('date-fns');
let prevfrmDate;
let prevtoDate;

const PurTransChkList = (props) => {

    const classes = useStyles();
    const [PurChkDATA, handleStats] = useState([]);
    const [frmDate, setfrmDate] = useState(subMonths(new Date(), 1));//useState(subMonths(new Date(), 60));
    const [toDate, settoDate] = useState(new Date());
    let CmphCode = '';


    useEffect(() => {
        FetchData()
    }, [])

    function getDates() {
        const $frmDate = format(new Date(frmDate), 'yyyy-MM-dd');
        const $toDate = format(new Date(toDate), 'yyyy-MM-dd');
        setfrmDate($frmDate);
        settoDate($toDate);
      //  FetchData();
    }

    const FetchData = async () => {
        const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/PurTransChkList?CmphCode=' + CmphCode + '&FrmDate=' + format(new Date(frmDate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(toDate), 'yyyy-MM-dd')); //data source;  
        const PurChkDATA = await data.json()
        handleStats(PurChkDATA)
        setFilter(PurChkDATA);
    }

    if (prevfrmDate !== frmDate || prevtoDate !== toDate) {
       // FetchData();
        prevfrmDate = frmDate;
        prevtoDate = toDate;
    }
    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = PurChkDATA.filter((row) => {
            return row.PurhRefNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CmphCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CmphName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PurdQnty.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PurdDflRate.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PurdDiscPer.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PurdDiscAmnt.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.PurdDflAmnt.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.ItemCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length < 1) {
            setFilter(PurChkDATA)
        }
        else {
            setFilter(filteredRows)
        }
    };
    const PurhRefNo_GROUP = [...PurChkDATA.reduce((r, o) => {
        const key = o.PurhRefNo;
        const item = r.get(key) || Object.assign({}, o);
        return r.set(key, item);
    }, new Map).values()];

    // //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    ///--------------------HELP WORK START----------------------------
    const [helpCmphCode, setCmphCode] = useState("");

    const handleDataChange = (newData) => {
        setCmphCode(newData);
    };
    const handleChange = (e) => {
        setCmphCode(e.target.value);
    };

    // const handleKeyDown = (event) => {
    //   if (event.key === "F1") {
    //     event.preventDefault();

    //     document.getElementById("HelpDialog").click();
    //   }
    // }

    function getCmphCode() {

        CmphCode = document.getElementById("helpCompanyTextbox").value;
    }

    function setFilterClick() {
        getCmphCode();
        getDates();
        FetchData();
    }

    // ----Grand TOTAL----------------------
    let total_Qnty = 0;
    let total_GrossSale = 0;
    let total_Amnt = 0;
    let total_Value = 0;


    PurChkDATA.map((row) => (
        total_Qnty += row.PurdQnty,
        total_GrossSale += row.PurdQnty > 0 ? row.PurdQnty * row.PurdDflRate : 0,
        total_Amnt += row.PurdDiscAmnt,
        total_Value += row.PurdDflAmnt
    ));

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(true);
        //----TOTAL----------------------
        let total_Qnty = 0;
        let total_GrossSale = 0;
        let total_Amnt = 0;
        let total_Value = 0;

        return (
            <Fragment>
                {/* {console.log(filter.map((row) => { return row.BookerCode.toString() }))} */}

                {filter.map((row) => { return row.PurhRefNo }).includes(row.PurhRefNo) && <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                    <StyledTableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </StyledTableCell>
                    <TableCell component="th" scope="row"><span className='fontStyle'>Bill No. : </span>{row.PurhRefNo}</TableCell>
                    <TableCell align="left"><span className='fontStyle'>Bill Date : </span>{format(new Date(row.PurhRefDate), 'dd/MM/yyyy')}</TableCell>
                    <TableCell align="left"><span className='fontStyle'>Company Code : </span>{row.CmphCode}</TableCell>
                    <TableCell align="left"><span className='fontStyle'>Company Name : </span>{row.CmphName}</TableCell>
                </TableRow>}

                {filter.map((row) => { return row.PurhRefNo }).includes(row.PurhRefNo) && <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {/* {row.BookerCode} */}
                                </Typography>

                                <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                                    {/* <Table size="small" aria-label="purchases"> */}
                                    <TableHead>
                                        <TableRow>
                                            {/* <StyledTableHeadCell align="left">Item Code</StyledTableHeadCell> */}
                                            {/* <StyledTableHeadCell align="left">Date</StyledTableHeadCell> */}
                                            <StyledTableHeadCell align="left">Item Name</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Quantity</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Rate</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Amount</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Disc. Rate</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Disc. Amount</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Value</StyledTableHeadCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filter.filter(Detrow => ((Detrow.PurhRefNo === row.PurhRefNo))).map((DetailRow) => (
                                            total_Qnty += DetailRow.PurdQnty,
                                            total_GrossSale += DetailRow.PurdQnty > 0 ? DetailRow.PurdQnty * DetailRow.PurdDflRate : 0,
                                            total_Amnt += DetailRow.PurdDiscAmnt,
                                            total_Value += DetailRow.PurdDflAmnt,

                                            <StyledTableRow key={DetailRow.Id}>
                                                {/* <StyledTableCell align="left">{DetailRow.ItemCode}</StyledTableCell> */}
                                                {/* <StyledTableCell align="left">{format(new Date(DetailRow.PurhRefDate), 'dd/MM/yyyy')} </StyledTableCell> */}
                                                <StyledTableCell align="left">{DetailRow.ItemDesc} </StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdDflRate} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdQnty > 0 ? DetailRow.PurdQnty * DetailRow.PurdDflRate : 0} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdDiscPer} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdDiscAmnt} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdDflAmnt} decimal={2} /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        <StyledTableRow>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} colSpan={1}>Sub Total</StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Qnty} decimal={2} /></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GrossSale} decimal={2} /></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Amnt} decimal={2} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Value} decimal={2} /></StyledTableFooterCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>}
            </Fragment>
        );
    }
    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title="Purchase Transaction Check List" />
            <div style={{ marginTop: "-1.5rem" }}>

            <TextField id="helpCompanyTextbox" value={helpCmphCode} onChange={handleChange} label="Company Code" variant="standard" //placeholder='Help Sale Order' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpCompany'} setHelpData={handleDataChange} />
                            </IconButton>
                        )
                    }} />

                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="From Date"
                        value={frmDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setfrmDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <DesktopDatePicker
                        label="To Date"
                        value={toDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                settoDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                </LocalizationProvider>
                <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={setFilterClick}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            {/* ----------------------*/}
            {/* <TablePagination
                component="div"
                rowsPerPageOptions={[1,5, 10, 15, 20, 25, 50, 100]}
                count={PurhRefNo_GROUP.length}
                showFirstButton
                showLastButton
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                //   onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <TableContainer component={Paper}>
                <Table id="myTable" aria-label="sticky table" size={'small'}>
                    {/* <TableHead>
                        <TableRow>
                            <TableCell />
                            <StyledTableHeadCell align="left">Bill No.</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Bill Date</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Company Code</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Company Name</StyledTableHeadCell>
                        </TableRow>
                    </TableHead> */}

                    <TableBody>
                        {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                        {(PurhRefNo_GROUP).map((row) => (
                            <Row key={row.Id} row={row} />
                        ))}
                    </TableBody>
                    {/* <StyledTableRow>
                        <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} colSpan={3}>Sub Total</StyledTableFooterCell>
                        <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Qnty} /></StyledTableFooterCell>
                        <StyledTableFooterCell></StyledTableFooterCell>
                        <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GrossSale} /></StyledTableFooterCell>
                        <StyledTableFooterCell></StyledTableFooterCell>
                        <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Amnt} /></StyledTableFooterCell>
                        <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Value} /></StyledTableFooterCell>
                    </StyledTableRow> */}
                </Table>
            </TableContainer>

        </div>
    )
}
export default PurTransChkList

