import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, LinearProgressWithLabel, NumberFormat } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "90vh",
  }
});

const InvWiseMerge_PartySumm = (props) => {

  const classes = useStyles();

  const [PSMDATA, setPSMDATA] = useState([]);
  const [error, setError] = useState(null); // Track error state
  const [loading, setLoading] = useState(false); // Track loading state
  const [progress, setProgress] = useState(0);
  // useEffect(() => {
  //   FetchData()
  // }, [])

  // const FetchData = async () => {
  //   const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/InvWiseMerge_PartySumm/'); //data source
  //   const PSMDATA = await data.json()
  //   handleStats(PSMDATA)
  //   setFilter(PSMDATA);
  // }


  useEffect(() => {
    let isMounted = true; // To track component mounting status
    const storedData = localStorage.getItem('PSMDATA');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (isMounted) {
        setData(parsedData);
        setLoading(false); // Stop loading since we have data
      }
    } else {
      fetchData(isMounted);
    }
    // Cleanup function to avoid memory leaks
    return () => {
      isMounted = false;
    };
  }, []);

  const setData = (data) => {
    setPSMDATA(data);
    setFilter(data);
  };

  const fetchData = async (isMounted) => {
    setLoading(true); // Start loading
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 95);
      });
    }, 2000);
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/InvWiseMerge_PartySumm/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (isMounted) {
        setProgress(100);
        setData(data);
        localStorage.setItem('PSMDATA', JSON.stringify(data));
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      if (isMounted) {
        setError('Failed to fetch data. Please try again later.');
        setProgress(0);
      }
    } finally {
      if (isMounted) {
        clearInterval(updateProgress);
        setLoading(false); // Stop loading after fetch attempt
      }
    }
  }
  //----TOTAL----------------------
  let total_NetInvAmnt = 0;
  let total_RecAmnt = 0;
  let total_InvBlnce = 0;

  PSMDATA.map((row) => (total_NetInvAmnt += row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt),
    total_RecAmnt += row.Coll_Amnt,
    total_InvBlnce += row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt) - row.Coll_Amnt
  ));

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = PSMDATA.filter((row) => {
      return row.CsCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.CsName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(PSMDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    //requestSearch
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="Party Summary (Merge)" />
      <div style={{ marginTop: "-2.3rem" }}>
        <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => requestSearch("")}
                style={{ display: showClearIcon }}
              >
                <CancelRoundedIcon />
              </IconButton>
            )
          }}
        />
      </div>
      {loading && <LinearProgressWithLabel value={progress} />}
      <TableContainer className={classes.container}>
        <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
          <TableHead >
            <TableRow>
              <StyledTableHeadCell align="left">Customer Code</StyledTableHeadCell>
              <StyledTableHeadCell align="left">Customer Name</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Net Inv. Amount</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Receipt Amount</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Invoice Balance</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filter.map((row) => (
              <StyledTableRow key={row.Id} hover >
                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/InvWiseMerge/" state={row}>{row.CsCode}</Button></StyledTableCell>
                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/InvWiseMerge/" state={row}>{row.CsName}</Button></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt)} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.Coll_Amnt} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt) - row.Coll_Amnt} /></StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <StyledTableFooterCell></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Grand Total</StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_NetInvAmnt} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_RecAmnt} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_InvBlnce} /></StyledTableFooterCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default InvWiseMerge_PartySumm

