import { React, useState, useEffect, Fragment } from 'react';
import { useLocation, Link, NavLink, useNavigate, Routes } from "react-router-dom";
import { Header, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '../components';
import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField, TableContainer, TableFooter
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { subMonths } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as Breadcrumbslink } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "90vh",
  },
  TableCell: {
    border: "1px solid rgba(0,0,0,0.2)",
    padding: 4
  }
});

const { format } = require('date-fns');

const SaleInvoiceDoc = () => {

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  let showData = true;

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [SIATA, handleStats] = useState([]);

  useEffect(() => {
    FetchData()
  }, [])
  let SalhInvNo = 0;
  let SalInvDate = '';
  const fSalhInvNo = state ? state.SalhInvNo : null;
  const fSalInvDate = state ? state.SalInvDate : null;
  //console.log(faccode)
  SalhInvNo = fSalhInvNo ? fSalhInvNo : SalhInvNo;
  SalInvDate = fSalInvDate ? fSalInvDate : SalInvDate;

  const FetchData = async () => {

    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/SaleInvoiceNew?SalhInvNo=' + SalhInvNo + '&SalInvDate=' + format(new Date(SalInvDate), 'yyyy-MM-dd')); //data source + '&ToDate=' + format(new Date(todate), 'yyyy-MM-dd')); //data source  
    const SIATA = showData ? await data.json() : [];
    handleStats(SIATA)
    setFilter(SIATA);
    // console.log(SIATA)
  }
  //----TOTAL----------------------
  let total_SRetAftAmnt = 0; // Total Calories
  let total = 0;
  let total_WHTRate = 0;
  let total_WHTAmnt = 0;
  let Itemcount = 0;

  SIATA.map((row) => (total_SRetAftAmnt += row.SaleReturnAfterAmnt,
    total += (row.SaleReturnAfterAmnt - row.SaleReturnAfterDiscAmnt) + (row.STax + row.SaleReturnAfterSTAmnt),
    total_WHTRate = row.SaldWHTRate,
    total_WHTAmnt += row.SaldWHTAmnt,
    Itemcount++
  ));

  //---------- Searching-----------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = SIATA.filter((row) => {
      return row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.ItemUnit.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.ItemBatchNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(SIATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //-----export pDf---------------------------
  return (
    <>
      {state && (
        <div>
          <div>
            <div className="set-breadcrumb">
              <Breadcrumbs aria-label="breadcrumb">
                {/* <Button className="hover-underline-animation" style={{ color: 'purple' }}  > */}
                {/* <Breadcrumbslink underline="" color="purple" href="/./InvWiseCredit_PartySumm">Party Summary</Breadcrumbslink> */}
                {/* <Breadcrumbslink underline="" color="purple" >Party Summary</Breadcrumbslink>
                </Button> */}
                <Button onClick={() => { navigate(-1) }} className="hover-underline-animation" style={{ color: 'purple' }}  >Invoice Wise</Button>
                <Button disabled={true}>Sale Invoice</Button>
              </Breadcrumbs>
            </div>
          </div>
          <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <Header category="" title="Sale Invoice Document" OtherInfo={"Customer : " + state.CsName} OtherInfo1={"Sale Invoice : " + state.SalhInvNo + " Date : " + format(new Date(state.SalInvDate), 'dd/MM/yyyy')} />

            <div style={{ marginTop: "-1.5rem" }}>
              <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => requestSearch("")}
                      style={{ display: showClearIcon }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )
                }}
              />
            </div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell align="left">Item Description</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Item Unit</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Batch</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Quantity</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Bonus Quantity</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Rate</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Gross Amount</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Disc. Per(%)</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">S.Tax</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Total</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter.map((row) => (
                    <StyledTableRow key={row.Id}>
                      {/* <StyledTableCell align="left" >
                        <div className='smallFont'>{row.ItemDesc}</div>
                        {format(new Date(row.ItemUnit), 'dd/MM/yyyy')}
                      </StyledTableCell> */}
                      <StyledTableCell align="left">{row.ItemDesc}</StyledTableCell>
                      <StyledTableCell align="left">{row.ItemUnit}</StyledTableCell>
                      <StyledTableCell align="left">{row.ItemBatchNo}</StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.SaleReturnAfterQnty} decimal={2} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.SaldFreeQnty} decimal={2} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.SaldGrossRate} decimal={2} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.SaleReturnAfterAmnt} decimal={2} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.SaleActDiscRate} decimal={2} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.STax} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={(row.SaleReturnAfterAmnt - row.SaleReturnAfterDiscAmnt) + (row.STax + row.SaleReturnAfterSTAmnt)} decimal={2} /></StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableFooterCell align="left">No of Item : {Itemcount} </StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SRetAftAmnt} decimal={2} /></StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total} /></StyledTableFooterCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} colSpan={3}>Advance Tax ( <NumberFormat value={total_WHTRate} decimal={2} /> % ) u/s 236(h) :</StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_WHTAmnt} decimal={2} /></StyledTableFooterCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold", }}>Total :</StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total + total_WHTAmnt} /></StyledTableFooterCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div style={{ color: 'purple' }}>
                <p align="left" style={{ fontWeight: "Bold", color: 'purple' }}>WARRANTY</p>
                <p><u>From 2A  (See Ruless 19 & 30) Warranty under section 23(1) (i) of the Drug Act. 1976.</u><br />
                  I. Mohd Ashraf, Office # 6-A, Gidumal Dayaram Rd., Ali's Appt., Parsi Colony, Soldier Bazar, KHI.being a resident in Pakistan.
                  Carrying on busniess at Karachi under the name of M/S.Madni Corporation, being an importer/authorized Agent/Indentor/Whole seller
                  do hereby give this warranty that the drugs described in this memo do not contravene in any way of the provision of Section 23 of
                  Drugs Act. 1976. </p>
                <p style={{ fontWeight: "Bold" }} >Note : Goods once sold cannot be taken back or exchanged.</p>
                <p>Attention : Homeopathic, Herbal or Unani/Ayurvedic, cosmetic and food product mention above if any are not covered
                  under this warranty.</p><br /><br />
                <p align="right" style={{ fontWeight: "Bold" }}> For Madni Corporation </p>
              </div> */}
          </div>
        </div>
      )}
    </>
  )
}
export default SaleInvoiceDoc