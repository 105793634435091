import { React, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, HelpDialog } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Box,
    TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from "dayjs";
import { ButtonGroup } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";
import { subMonths } from 'date-fns'
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});

const { format } = require('date-fns');
let prevfrmDate;
let prevtoDate;

const InvSalesSummary = (props) => {

    const classes = useStyles();
    const [INSSDATA, handleStats] = useState([]);
    const [frmDate, setfrmDate] = useState(new Date());//useState(subMonths(new Date(), 60));
    const [toDate, settoDate] = useState(new Date());

    let bookerCode = '';
    let sOrder = '0';

    useEffect(() => {
        FetchData()
    }, [])

    function getDates() {
        const $frmDate = format(new Date(frmDate), 'yyyy-MM-dd');
        const $toDate = format(new Date(toDate), 'yyyy-MM-dd');
        setfrmDate($frmDate);
        settoDate($toDate);
        // FetchData();
    }

    const FetchData = async () => {
        const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/InvSalesSummary?BookerCode=' + bookerCode + '&SOrderRefNo=' + sOrder + '&FrmDate=' + format(new Date(frmDate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(toDate), 'yyyy-MM-dd')); //data source;  
        const INSSDATA = await data.json()
        handleStats(INSSDATA)
        setFilter(INSSDATA);
    }

    if (prevfrmDate !== frmDate || prevtoDate !== toDate) {
        //  FetchData();
        prevfrmDate = frmDate;
        prevtoDate = toDate;
    }
    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        if (searchedVal !== "") {
            setRowsPerPage(-1);
        } else {
            setRowsPerPage(1);
        }
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = INSSDATA.filter((row) => {
            return row.BookerCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.BookerName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.SOrderhRefNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.SalhInvNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.MarketName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CsName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length < 1) {
            setFilter(INSSDATA)
        }
        else {
            setFilter(filteredRows)
        }
    };
    const booker_SOrderNo_GROUP = [...INSSDATA.reduce((r, o) => {
        const key = o.BookerCode + '-' + o.SOrderhRefNo;
        const item = r.get(key) || Object.assign({}, o);
        return r.set(key, item);
    }, new Map).values()];

    // //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    ///--------------------HELP WORK START----------------------------
    const [helpBookerCode, sethelpBookerCode] = useState("");
    
    const handleDataChangeBC = (newData) => {
        sethelpBookerCode(newData);
    };
    const handleChangeBC = (e) => {
        sethelpBookerCode(e.target.value);
    };

    // const [helpSOrder, setHelpSOrder] = useState(0);
    // const handleDataChangeSO = (newData) => {
    //     setHelpSOrder(newData);
    // };
    // const handleChangeSO = (e) => {
    //     setHelpSOrder(e.target.value);
    //     if (e.target.value === "") {
    //         setHelpSOrder(0);
    //     }
    // };

    // const handleKeyDown = (event) => {
    //   if (event.key === "F1") {
    //     event.preventDefault();

    //     document.getElementById("HelpDialog").click();
    //   }
    // }


    function getBookerCode() {

        bookerCode = document.getElementById("helpBookerTextbox").value;
    }
    // function getSOrder() {

    //     sOrder = document.getElementById("helpSOrderTextbox").value;
    // }
    function setFilterClick() {
        getBookerCode();
        // getSOrder();
        getDates();
        FetchData();
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(true);
        //----TOTAL----------------------
        let total_Sale = 0;
        let total_Value = 0;
        let total_Total = 0;
        let Invoicecount = 0;
        let Customercount = 0;
        let csCount = [];
        return (
            <Fragment>
                {/* {console.log(filter.map((row) => { return row.BookerCode.toString() }))} */}
                {filter.map((row) => { return row.BookerCode.toString() }).includes(row.BookerCode) && <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                    <StyledTableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                        {row.BookerCode}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.BookerName}</StyledTableCell>
                    <StyledTableCell align="left">{row.SOrderhRefNo}</StyledTableCell>
                    <StyledTableCell align="left">{format(new Date(row.SOrderhRefDate), 'dd/MM/yyyy')}</StyledTableCell>
                </TableRow>}
                {filter.map((row) => { return row.BookerCode.toString() }).includes(row.BookerCode) && <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {/* {row.BookerCode} */}
                                </Typography>

                                <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                                    {/* <Table size="small" aria-label="purchases"> */}
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableHeadCell align="left">Invoice No.</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">Customer Name</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">Sub Area</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Inv. Limit</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">Type</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Chq.Cr./ <br />Cons. Dt.</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Sale</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Return Number</StyledTableHeadCell>
                                            <StyledTableHeadCell align="left">Return Date</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Return Value</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Total</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right"></StyledTableHeadCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filter.filter(Detrow => ((Detrow.BookerCode === row.BookerCode && Detrow.SOrderhRefNo === row.SOrderhRefNo))).map((DetailRow) => (
                                            total_Sale += DetailRow.SaldDflAmnt,
                                            total_Value += DetailRow.SRetdDflAmnt,
                                            total_Total += DetailRow.SaldDflAmnt - DetailRow.SRetdDflAmnt,
                                            Invoicecount++,
                                            //Customercount++,
                                            !csCount.includes(DetailRow.CsName) ? csCount.push(DetailRow.CsName) : "",

                                            Customercount = csCount.length,


                                            <StyledTableRow key={DetailRow.Id}>
                                                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/SaleInvoiceDoc/" state={DetailRow}>{DetailRow.SalhInvNo}</Button></StyledTableCell>
                                                {/* <StyledTableCell align="left">{DetailRow.SalhInvNo}</StyledTableCell> */}
                                                <StyledTableCell align="left">{DetailRow.CsName}</StyledTableCell>
                                                <StyledTableCell align="left">{DetailRow.MarketName} </StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.CsCreditLimit} /></StyledTableCell>
                                                <StyledTableCell align="left" >{DetailRow.SalhCrdType === "Consignment" ? "Cons." : DetailRow.SalhCrdType} </StyledTableCell>
                                                <StyledTableCell align="left">{format(new Date(DetailRow.SalhDueDate), 'dd/MM/yyyy')} </StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.SaldDflAmnt} /></StyledTableCell>
                                                <StyledTableCell align="right">{DetailRow.SRethRefNo} </StyledTableCell>
                                                <StyledTableCell disabled={true} align="left">{format(new Date(DetailRow.SRethRefDate), 'dd/MM/yyyy') === "01/01/1900" ? "" : format(new Date(DetailRow.SRethRefDate), 'dd/MM/yyyy')}</StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.SRetdDflAmnt} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.SaldDflAmnt - DetailRow.SRetdDflAmnt} /></StyledTableCell>
                                                <StyledTableCell align="left">{DetailRow.SalhMiscStatus} </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        <StyledTableRow>
                                            <StyledTableFooterCell align="left" colSpan={2}> No. of Invoices : {Invoicecount} </StyledTableFooterCell>
                                            <StyledTableFooterCell align="left" colSpan={2}> No. of Customers : {Customercount} </StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} colSpan={2}>Sub Total</StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Sale} /></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Value} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Total} /></StyledTableFooterCell>
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>}
            </Fragment>
        );
    }
    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title="Invoice Sales Summary - Credit - Before Generate" />
            <div style={{ marginTop: "-1.5rem" }}>

                <TextField id="helpBookerTextbox" value={helpBookerCode} onChange={handleChangeBC} label="Booker Code" variant="standard" //placeholder='Help Booker' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpBooker'} setHelpData={handleDataChangeBC} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                {/* <TextField id="helpSOrderTextbox" value={helpSOrder} type="number" onChange={handleChangeSO} label="Sale Order No." variant="standard" //placeholder='Help Sale Order' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpSOrder'} setHelpData={handleDataChangeSO} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}


                {/* <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={() => { getBookerCode(); }}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="From Date"
                        value={frmDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setfrmDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <DesktopDatePicker
                        label="To Date"
                        value={toDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                settoDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                </LocalizationProvider>
                <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={() => { setFilterClick() }}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            {/* ----------------------*/}
            <TablePagination
                component="div"
                rowsPerPageOptions={[1, 5, 10, 15, 20, 25, 50, 100, { label: 'All', value: -1 }]}
                count={booker_SOrderNo_GROUP.length}
                showFirstButton
                showLastButton
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                //   onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer component={Paper}>
                <Table id="myTable" aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <StyledTableHeadCell align="left">Booker Code</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Booker Name</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Sale Order No.</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Sale Order Date</StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(booker_SOrderNo_GROUP).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <Row key={row.Id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
export default InvSalesSummary

