import { React, useState, useEffect, Fragment } from 'react';
import { useLocation, Link, NavLink, useNavigate, Routes } from "react-router-dom";
import { Header, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '../components';
import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField, TableContainer, TableFooter
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { subMonths } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as Breadcrumbslink } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "90vh",
  },
  TableCell: {
    border: "1px solid rgba(0,0,0,0.2)",
    padding: 4
  }
});

const { format } = require('date-fns');
let prevAsOnDate;

const InvWiseConsigment = (props) => {

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  let showData = true;

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [IWCATA, handleStats] = useState([]);
  const [AsOnDate, setAsOnDate] = useState(new Date());

  useEffect(() => {
    FetchData()
  }, [])
  let CsCode = '';
  const fCsCode = state ? state.CsCode : null;
  CsCode = fCsCode ? fCsCode : CsCode;

  function getDates() {
    const $AsOnDate = format(new Date(AsOnDate), 'yyyy-MM-dd');
    setAsOnDate($AsOnDate);
    FetchData();
  }

  const FetchData = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/InvWiseCons?CsCode=' + CsCode + '&AsOnDate=' + format(new Date(AsOnDate), 'yyyy-MM-dd')); //data source + '&ToDate=' + format(new Date(todate), 'yyyy-MM-dd')); //data source  
    const IWCATA = showData ? await data.json() : [];
    handleStats(IWCATA)
    setFilter(IWCATA);
  }
  if (prevAsOnDate !== AsOnDate) {
    FetchData();
    prevAsOnDate = AsOnDate;
  }
  //----TOTAL----------------------
  let total_InvAmnt = 0;
  let total_RetAmnt = 0;
  let total_NetInvAmnt = 0;
  let total_RecAmnt = 0;
  let total_InvBlnce = 0;

  IWCATA.map((row) => (total_InvAmnt += row.Opng_Amnt + row.Sale_Amnt,
    total_RetAmnt += row.Retu_Amnt,
    total_NetInvAmnt += row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt),
    total_RecAmnt += row.Coll_Amnt,
    total_InvBlnce += row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt) - row.Coll_Amnt
  ));

  //---------- Searching-----------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = IWCATA.filter((row) => {
      return row.SalhInvNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.CsName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
    });
    if (searchedVal.length < 1) {
      setFilter(IWCATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //-----export pDf---------------------------
  return (
    <>
      {state && (
        <div>
          <div>
            <div className="set-breadcrumb">
              <Breadcrumbs aria-label="breadcrumb">
                {/* <Button className="hover-underline-animation" style={{ color: 'purple' }}  >
                  <Breadcrumbslink underline="" color="purple" href="/./InvWiseCredit_PartySumm">Party Summary</Breadcrumbslink>
                </Button> */}
                <Button onClick={() => { navigate(-1) }} className="hover-underline-animation" style={{ color: 'purple' }}  >Party Summary (Cons.)</Button>

                {/* <Button onClick={() => { navigate(-1) }} className="hover-underline-animation" style={{ color: 'purple' }}  >
                  <Breadcrumbslink underline="" color="purple" href="/./InvWiseConsigment">Invoice (Cons.)</Breadcrumbslink>
                </Button> */}

                <Button disabled={true}> Invoice (Consigment) </Button>
              </Breadcrumbs>
            </div>
          </div>
          <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <Header category="" title="Invoice Wise (Consigment)" OtherInfo={"Customer : " + state.CsCode + " - " + state.CsName} />
            <div style={{ marginTop: "-1.5rem" }}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="As On Date"
                  value={AsOnDate}
                  onChange={(newValue) => {
                    if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                      setAsOnDate(newValue);
                    }
                  }}
                  inputFormat={"DD/MM/YYYY"}
                  renderInput={(params) => <TextField {...params} />}
                  className="sameLineTabledatetime"
                />
              </LocalizationProvider>
              <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
              </ButtonGroup>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <br />
              <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => requestSearch("")}
                      style={{ display: showClearIcon }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )
                }}
              />
            </div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead >
                  <TableRow>
                    <StyledTableHeadCell align="center"> Invoice No. <br />Invoice Date</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Company Code</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Customer Name</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Area Name</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Invoice Amount</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Return Amount</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Net Inv. Amount</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Receipt Amount</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Invoice Balance</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter.map((row) => (
                    <StyledTableRow key={row.Id} hover >
                      <StyledTableCell align="left">
                        <Button style={{ fontWeight: "400" }} size='small' color="inherit" variant='text' component={Link} to="/SaleInvoiceDoc/" state={row}>
                          {row.SalhInvNo}
                          <br />
                          {format(new Date(row.SalInvDate), 'dd/MM/yyyy')}
                        </Button>
                      </StyledTableCell>
                      {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/SaleInvoiceDoc/" state={row}>{row.SalhInvNo}</Button></StyledTableCell>
                      <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/SaleInvoiceDoc/" state={row}>{format(new Date(row.SalInvDate), 'dd/MM/yyyy')}</Button></StyledTableCell> */}
                      {/* <StyledTableCell align="left" >
                        <div className='smallFont'>{row.SalhInvNo}</div>
                        {format(new Date(row.SalInvDate), 'dd/MM/yyyy')}
                      </StyledTableCell> */}
                      {/* <StyledTableCell align="left">{format(new Date(row.SalInvDate), 'dd/MM/yyyy')}</StyledTableCell> */}
                      <StyledTableCell align="left">{row.CmphCode}</StyledTableCell>
                      <StyledTableCell align="left">{row.CsName}</StyledTableCell>
                      <StyledTableCell align="left">{row.AreaName}</StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.Opng_Amnt + row.Sale_Amnt} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.Retu_Amnt} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt)} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.Coll_Amnt} /></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.Opng_Amnt + (row.Sale_Amnt - row.Retu_Amnt) - row.Coll_Amnt} /></StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableFooterCell></StyledTableFooterCell><StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Grand Total</StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_InvAmnt} /></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_RetAmnt} /></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_NetInvAmnt} /></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_RecAmnt} /></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_InvBlnce} /></StyledTableFooterCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </>
  )
}
export default InvWiseConsigment