import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { subMonths } from 'date-fns'
import { ButtonGroup } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";
import '../App.css';

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});
const { format } = require('date-fns');
let prevfrmDate;
let prevtoDate;

const ItemWisePurchase = (props) => {

    const classes = useStyles();

    const [ItmWisePurData, handleStats] = useState([]);
    const [frmDate, setFrmDate] = useState(subMonths(new Date(),1));//useState(subMonths(new Date(),1));;// useState(subMonths(new Date(toDate.value.Month, 1)));
    const [toDate, setToDate] = useState(new Date());

    useEffect(() => {
        FetchData()
    }, [])

    function getDates() {
        const $frmDate = format(new Date(frmDate), 'yyyy-MM-dd');
        const $toDate = format(new Date(toDate), 'yyyy-MM-dd');
        setFrmDate($frmDate);
        setToDate($toDate);
        FetchData();
    }

    const FetchData = async () => {
        const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/ItemWisePurchase?FrmDate=' + format(new Date(frmDate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(toDate), 'yyyy-MM-dd')); //data source
        const ItmWisePurData = await data.json()
        handleStats(ItmWisePurData)
        setFilter(ItmWisePurData);
    }

    if (prevfrmDate !== frmDate || prevtoDate !== toDate) {
        FetchData();
        prevfrmDate = frmDate;
        prevtoDate = toDate;
    }

    // ----TOTAL----------------------
    let total_GrossSale = 0;
    let total_Amount = 0;
    let total_STax = 0;
    let total_NetSale = 0;
    // let total_Avg = 0;


    ItmWisePurData.map((row) => (
        total_GrossSale += row.PurdGrossValue,
        total_Amount += row.PurdDiscAmnt,
        total_STax += row.PurdSTaxAmnt,
        total_NetSale += row.PurdDflAmnt
        // total_Avg += row.PurdDiscAmnt,
    ));

    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = ItmWisePurData.filter((row) => {
            return row.CmphName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
            || row.PurdGrossValue.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
            || row.PurdDiscAmnt.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
            || row.PurdSTaxAmnt.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
            || row.PurdDflAmnt.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length < 1) {
            setFilter(ItmWisePurData)
        }
        else {
            setFilter(filteredRows)
        }
    };
    //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title="Item Wise Purchase Summary" />
            <div style={{ marginTop: "-1.5rem" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="From Date"
                        value={frmDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setFrmDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <DesktopDatePicker
                        label="To Date"
                        value={toDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setToDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                </LocalizationProvider>
                <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            <TableContainer className={classes.container}>
                <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead >
                        {/* <TableRow>
                            <StyledTableHeadCell></StyledTableHeadCell><StyledTableHeadCell></StyledTableHeadCell>
                            <StyledTableHeadCell align="center" colSpan={2} style={{ fontWeight: "Bold" }}><u>---Sales Discount---</u></StyledTableHeadCell>
                            <StyledTableHeadCell></StyledTableHeadCell><StyledTableHeadCell></StyledTableHeadCell>
                        </TableRow> */}
                        <TableRow>
                            <StyledTableHeadCell align="left">Company Name</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Gross Purchases</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Disc. Average</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Disc. Amount</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Sales Tax</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Net Purchases</StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filter.map((row) => (
                            <StyledTableRow key={row.Id} hover >
                                <StyledTableCell align="left"> {row.CmphName}</StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.PurdGrossValue}/></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.PurdDiscAmnt > 0 ? (row.PurdDiscAmnt / row.PurdGrossValue * 100) : 0} decimal={2} /></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.PurdDiscAmnt}/></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.PurdSTaxAmnt}/></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.PurdDflAmnt}/></StyledTableCell>
                            </StyledTableRow>
                        ))}
                        <StyledTableRow>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Grand Total</StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GrossSale}/></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Amount > 0 ? (total_Amount / total_GrossSale * 100) : 0} decimal={2} /></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Amount}/></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_STax}/></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_NetSale}/></StyledTableFooterCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default ItemWisePurchase