import React from 'react'
import {
    TableCell
  } from "@material-ui/core";
  import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles({
    root: {
      fontSize:"smaller!important",
    },
  })(TableCell);



export default StyledTableCell