import React from 'react'
import '../App.css';

const Header = ({ category, title, OtherInfo,OtherInfo1 }) => {
  return (
    <div className="mb-10">
      <p className="text-gray-400">
        {category}
      </p>

      <p className="text-3xl font-extrabold tracking-tight text-slate-900 header-title">
        {title} 
        {/* <br /> */}
          {/* <br /> */}
        <p className="header-info">
        {/* <br /> */}
          {OtherInfo} 
          <br />
          {OtherInfo1}
          {/* <br /> */}
        </p>
      </p>
    </div>
  )
}

export default Header