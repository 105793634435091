import React from 'react'
import {
    TableCell
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableFooterCell = withStyles({
    root: {
        fontWeight:"bold",
        color:"#5946B2",
    },
})(TableCell);

export default StyledTableFooterCell