import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});

const SupplierLedgerSumm = (props) => {

    const classes = useStyles();

    const [SuppLedgerSumData, handleStats] = useState([]);

    useEffect(() => {
        FetchData()
    }, [])

    const FetchData = async () => {
        const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/SupplierLedger_Summ/'); //data source
        const SuppLedgerSumData = await data.json()
        handleStats(SuppLedgerSumData)
        setFilter(SuppLedgerSumData);
    }
    //----TOTAL----------------------
    let total_Opening = 0;
    let total_Debit = 0;
    let total_Credit = 0;
    let total_Balance = 0;


    SuppLedgerSumData.map((row) => (
        total_Opening += row.CmphOpening,
        total_Debit += row.DrAmount,
        total_Credit += row.CrAmount,
        total_Balance += row.CmphOpening + (row.DrAmount - row.CrAmount)

       ));

    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = SuppLedgerSumData.filter((row) => {
            return row.CmphCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CmphName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CmphOpening.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.DrAmount.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.CrAmount.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length < 1) {
            setFilter(SuppLedgerSumData)
        }
        else {
            setFilter(filteredRows)
        }
    };
    //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title="Supplier Summary" />
            <div style={{ marginTop: "-2.3rem" }}>
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            <TableContainer className={classes.container}>
                <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead >
                        <TableRow>
                            <StyledTableHeadCell align="left">Company Code</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Company Name</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Opening</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Debit</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Credit</StyledTableHeadCell>
                            <StyledTableHeadCell align="right">Balance</StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filter.map((row) => (
                            <StyledTableRow key={row.Id} hover >
                                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/SupplierLedger/" state={row}>{row.CmphCode}</Button></StyledTableCell>
                                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/SupplierLedger/" state={row}>{row.CmphName}</Button></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.CmphOpening} /></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.DrAmount} /></StyledTableCell>
                                {/* <StyledTableCell align="right"><NumberFormat value={row.VrType === "" ? 0 : row.DrAmount} /></StyledTableCell> */}
                                <StyledTableCell align="right"><NumberFormat value={row.CrAmount} /></StyledTableCell>
                                <StyledTableCell align="right"><NumberFormat value={row.CmphOpening + (row.DrAmount - row.CrAmount)} /></StyledTableCell>
                            </StyledTableRow>
                        ))}
                        <StyledTableRow>
                            <StyledTableFooterCell></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Grand Total</StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Opening} /></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Debit} /></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Credit} /></StyledTableFooterCell>
                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Balance} /></StyledTableFooterCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default SupplierLedgerSumm

